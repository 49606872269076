var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "unOwnerMgr-container" },
    [
      _c("list", {
        ref: "unOwnerMgrList",
        attrs: {
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          exportPermission: "export",
          searchParams: _vm.searchParams,
          isMultiSelect: true,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "既存业主房号导入", permission: "import1" },
                  on: {
                    click: function ($event) {
                      return _vm.gotoImport(1)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "导入", permission: "import" },
                  on: { click: _vm.gotoImport },
                }),
                _c("v-button", {
                  attrs: { text: "新增未注册用户", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-datepicker", {
                  attrs: {
                    type: "rangedatetimer",
                    defaultTimeType: "week",
                    maxDate: _vm.maxDate,
                    startTime: _vm.searchParams.startInTime,
                    endTime: _vm.searchParams.endInTime,
                    label: "录入时间",
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startInTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startInTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endInTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endInTime", $event)
                    },
                  },
                }),
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "数据来源", options: _vm.userSourceOps },
                  model: {
                    value: _vm.searchParams.userSource,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userSource", $$v)
                    },
                    expression: "searchParams.userSource",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityIdConfig,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "苑" },
                  model: {
                    value: _vm.searchParams.address,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "address", $$v)
                    },
                    expression: "searchParams.address",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "幢" },
                  model: {
                    value: _vm.searchParams.buildNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "buildNum", $$v)
                    },
                    expression: "searchParams.buildNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "单元" },
                  model: {
                    value: _vm.searchParams.unitNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "unitNum", $$v)
                    },
                    expression: "searchParams.unitNum",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "室" },
                  model: {
                    value: _vm.searchParams.roomNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "roomNum", $$v)
                    },
                    expression: "searchParams.roomNum",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "性别", options: _vm.sexOps },
                  model: {
                    value: _vm.searchParams.sex,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "sex", $$v)
                    },
                    expression: "searchParams.sex",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "身份", options: _vm.userTypeOps },
                  model: {
                    value: _vm.searchParams.userType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userType", $$v)
                    },
                    expression: "searchParams.userType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "证件号" },
                  model: {
                    value: _vm.searchParams.cardNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "cardNum", $$v)
                    },
                    expression: "searchParams.cardNum",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属公司" },
                      model: {
                        value: _vm.searchParams.regionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "regionId", $$v)
                        },
                        expression: "searchParams.regionId",
                      },
                    },
                    "v-select2",
                    _vm.regionIdConfig,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量删除",
                    permission: "delete",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batchDelete(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "删除", type: "text", permission: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.delSingle(scope.row)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }