<template>
  <div class="unOwnerMgr-container">
    <list ref="unOwnerMgrList" :searchUrl="searchUrl" :exportUrl="exportUrl" exportPermission="export" :searchParams.sync="searchParams"  :isMultiSelect="true" :headers="headers" >
      <template #headerSlot>
        <v-button text="既存业主房号导入" permission="import1" @click="gotoImport(1)"></v-button>
        <v-button text="导入" permission="import" @click="gotoImport"></v-button>
        <v-button text="新增未注册用户" permission="add" @click="create"></v-button>
      </template>
      
      <template #searchSlot>
        <v-datepicker type="rangedatetimer" defaultTimeType="week" :maxDate="maxDate" :startTime.sync="searchParams.startInTime" :endTime.sync="searchParams.endInTime" label="录入时间"/>
        <v-input v-model="searchParams.userName" label="姓名"></v-input>
        <v-input v-model="searchParams.mobileNum" label="手机号"></v-input>
        <v-select v-model="searchParams.userSource" label="数据来源" :options="userSourceOps"></v-select>
        <v-select2 v-model="searchParams.communityId" label="所属项目"  v-bind="communityIdConfig"></v-select2>
        <v-input v-model="searchParams.address" label="苑"></v-input>
        <v-input v-model="searchParams.buildNum" label="幢"></v-input>
        <v-input v-model="searchParams.unitNum" label="单元"></v-input>
        <v-input v-model="searchParams.roomNum" label="室"></v-input>
        <v-select v-model="searchParams.sex" label="性别" :options="sexOps"></v-select>
        <v-select v-model="searchParams.userType" label="身份" :options="userTypeOps"></v-select>
        <v-input v-model="searchParams.cardNum" label="证件号"></v-input>
        <v-select2 v-model="searchParams.regionId" label="所属公司"  v-bind="regionIdConfig"></v-select2>
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          permission="delete"
          @click="batchDelete(scope.selectedData)"
          :disabled="scope.selectedData.ids.length === 0"
        ></v-button>
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button text="删除" type="text" permission="delete" @click="delSingle(scope.row)"></v-button>

      </template>
    </list>
  </div>
</template>
<script>
import { getUnregisterList,unregisterDelete, exportUrl } from './api'
import * as commonData from '../ownerMgrForm/map'
import { communityParams, regionParams } from '@/common/select2Params'
import qs from 'qs'
export default {
  name: 'UnRegisterOwnerMgr',
  components: {},
  data () {
    return {
      searchUrl: getUnregisterList,
      exportUrl: exportUrl,
      maxDate: new Date(),
      searchParams: {
        startInTime: '',
        endInTime: '',
        userName: '',
        mobileNum: '',
        userSource: undefined,
        communityId: undefined,
        address: '',
        buildNum: '',
        unitNum: '',
        roomNum: '',
        sex: undefined,
        userType: undefined,
        realnameExamine: undefined,
        cardNum: '',
        regionId: undefined
      },
      communityIdConfig: communityParams,
      regionIdConfig: regionParams,
      userSourceOps: commonData.setUserSourceOps(1),
      sexOps: commonData.searchSexOps,
      userTypeOps: commonData.setUserTypeOps(1),
      headers: [
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'alias',
          label: '称谓'
        },
        {
          prop: 'sex',
          label: '性别',
          formatter (row) {
            let sex = commonData._sex[row.sex]
            return sex
          }
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'intime',
          label: '录入时间'
        },
        {
          prop: 'sex',
          label: '数据来源',
          formatter (row) {
            let userSource = commonData._userSource[row.userSource]
            return userSource
          }
        },
        {
          prop: 'address',
          label: '住址'
        },
        {
          prop: 'userTypeName',
          label: '身份'
        },
        {
          prop: 'cardNum',
          label: '证件号'
        },
        {
          prop: 'orgName',
          label: '组织'
        },
      ],
      fromIotImportantPersonManage:undefined
      // .query.fromIotImportantPersonManage
    }
  },
  mounted(){
    let {fromIotImportantPersonManage} = this.$route.query
    this.fromIotImportantPersonManage = fromIotImportantPersonManage
    // console.log(this.fromIotImportantPersonManage,'this.fromIotImportantPersonManage');
  },  
  methods: {
    edit (row) {
      this.$router.push({
        name: 'ownerMgrForm',
        query: {
          id: row.id,
          type: 0,
          communityId: row.communityId,
          orgId: row.orgId,
          fromIotImportantPersonManage: this.fromIotImportantPersonManage
        }
      })
    },
     // 批量删除
    async batchDelete(selectedData) {
      const ids = selectedData.ids
      const assetIds = selectedData.data.map(v => v.assetId)
      const result = await this.$confirm(
        `确定删除选中的${ids.length}条业主信息吗?`,
        {
          title: '提示',
        }
      )
      if (result) {
        let data = {}
        data.ids = ids
        data.assetIds = assetIds
        this.$axios
          .post(unregisterDelete, data,{'content-type':'application/json'})
          .then((res) => {
            if (res.status === 100) {
              this.$message.success('删除成功')
              this.$refs.unOwnerMgrList.searchData()
              this.$refs.unOwnerMgrList.searchPanelSearchData()
            }
          }).catch(err=>{
            console.log(err)
          })
      }
    },
    // 点击删除
    async delSingle(row) {
     
      const ids =[row.id]
      const assetIds = [row.assetId]
      const result = await this.$confirm(
        `确定删除 ${row.userTypeName} ${row.userName} ?`,
        {
          title: '提示',
        }
      )
      if (result) {
        let data = {}
        data.ids = ids
        data.assetIds = assetIds
        
        this.$axios
          .post(unregisterDelete, data,{'content-type':'application/json'})
          .then((res) => {
            console.log(res)
            if (res.status === 100) {
              this.$message.success('删除成功')
              this.$refs.unOwnerMgrList.searchData()
              this.$refs.unOwnerMgrList.searchPanelSearchData()
              // this.getNodeFirstList()
            }
          })
      }
    },
    create () {
      this.$router.push({
        name: 'ownerMgrForm',
        query: {
          type: 0,
          isAdd: true
        }
      })
    },
    gotoImport (type) {
      this.$router.push({
        name: 'UnRegisterOwnerMgrImport',
        query: {
            type
        }
      })
    }
  }
}
</script>
